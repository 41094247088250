import React, { Fragment, useState } from 'react';
import apiServer from '../services/api';
import Alert from '../components/Alert';

export default function Upload() {

    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('Drop files or select to upload');
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [select, setSelect] = useState(0);
    const [alert, setAlert] = useState('');

    const onChange = e => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const onSelectChange = e => {
        setSelect(e.target.value);
    }

    const onSubmit = async e => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);
        formData.append('select', select);

        try {
            const res = await apiServer.get('/sanctum/csrf-cookie').then(() => {

                apiServer.post('/api/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept': 'application/json'
                    },
                    onUploadProgress: ProgressEvent => {
                        setUploadPercentage(parseInt(Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)));
                    }
                }).then(function (response){
                    if(response.status == 200){
                        setAlert('Success');
                    }
                });
            });
        } catch (error) {
            setAlert('Error found!');
        }
    }

    return (
        <div className="container w-3/4 mx-auto bg-white mt-5 shadow-xl">
            { alert ? <Alert msg={alert} /> : null }
			<div className="flex flex-row p-2 justify-center">
                <Fragment>
                    <form onSubmit={onSubmit}>
                        <div className="border border-dashed border-gray-500 relative">
                            <input type="file" className="cursor-pointer relative block opacity-0 w-full h-full p-20 z-50" onChange={onChange} required />
                            <div className="text-center p-20 absolute top-0 right-0 left-0 m-auto">
                                <h4>
                                    {fileName}
                                </h4>
                            </div>
                        </div>
                        <h1 className="pt-2 sm:pt-5 text-black pt-5">Upload Progress <span className=" text-s float-right text-red-500">{uploadPercentage}%</span></h1>
                        <div className="w-full mt-2 h-4 relative rounded-full overflow-hidden">
                            <div className=" w-full h-full bg-gray-200 absolute"></div>
                            <div className=" h-full bg-red-500 sm:bg-yellow-400 absolute" style={{width: `${uploadPercentage}%`}}></div>
                        </div>
                        <div className='w-full'>
                            <div className="relative inline-flex float-left my-5">
                                <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fillRule="nonzero"/></svg>
                                <select defaultValue={-1} onChange={onSelectChange} className="border border-gray-300 rounded-md text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none" required>
                                    <option value={-1} disabled>Pilih Mode</option>
                                    <option value={1}>Upload Masal (Lambat)</option>
                                    <option value={2}>Upload Perbaris</option>
                                </select>
                            </div>
                            <input type='submit' value='Upload' className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 my-5 border-b-4 border-blue-900 hover:border-blue-800 rounded float-right" />
                        </div>
                    </form>
                </Fragment>
			</div>                
		</div>
    )
}
