import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Nav from './layouts/Header';
import Dashboard from './pages/Dashboard';
import Upload from './pages/Upload';

function App() {
    return (
        <Router>
            <div className='all'>
                <Nav />
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/upload" element={<Upload />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
