import React, { useState, useEffect } from 'react';
import Datatable from '../components/Datatable';
import apiServer from '../services/api';

async function getSaldo() {
    try {
        const response = await apiServer.get('/api/saldo');
        return response.data.saldo;
    } catch (error) {
        return error.data.message;
    }
}

function Dashboard () {

	const [data, setData] = useState([]);
	const [q, setQ] = useState("");

    useEffect(() => {
        getSaldo()
            .then(data => setData(data));
    }, [])

	return (
		<div className="container w-3/4 mx-auto bg-white mt-5 shadow-xl">
			<div className="flex flex-row p-2">
				<Datatable data={data} />
			</div>                
		</div>
	)
}

export default Dashboard;